
import { api } from "@/api/api";
import {
  ApiAttachmentDto,
  ApiGetCourseDto,
  ApiGetCourseParticipantKursAdminDto,
  ApiGetMessageResultDto,
} from "@/api/generated/Api";
import CourseViewFileModal from "@/components/course/details/files/CourseViewFileModal.vue";
import CourseSendMessageModal from "@/components/course/details/messages/CourseSendMessageModal.vue";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { formatDateTime } from "@/shared/helpers/dateHelpers";
import { FilePreviewData } from "@/shared/helpers/fileViewHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { mapMessageResponse } from "@/shared/helpers/messageHelpers";
import { useNavigateBack } from "@/shared/helpers/navigationHelpers";
import "@/shared/styles/toastui-editor.scss";
import { useRoute, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";
import { Viewer } from "@toast-ui/vue-editor";
import { computed, defineComponent, onMounted, Ref, ref } from "@vue/composition-api";

export default defineComponent({
  name: "CourseMessagePage",
  components: { BaseModal, CourseSendMessageModal, BaseLayout, Viewer, CourseViewFileModal },
  setup() {
    const route = useRoute();
    const store = useStore<StoreState>();
    const courseId = +route.params.id;
    const course = ref<ApiGetCourseDto | null>(null);
    const messageId = +route.params.messageId;
    const showReplyModal = ref(false);
    const filePreviewModalData = ref(getInitialModalData());
    const message = ref<ApiGetMessageResultDto | null>(null);
    const participants = ref<ApiGetCourseParticipantKursAdminDto[]>([]);

    const markUnread = async () => {
      await setMessageReadStatus(messageId, false);
      useNavigateBack(SingleCourseRouteNames.CourseMessages);
    };

    const markRead = async () => {
      await setMessageReadStatus(messageId, true);
      useNavigateBack(SingleCourseRouteNames.CourseMessages);
    };

    const answerEmail = () => {
      showReplyModal.value = true;
    };

    const showFilePreviewModal = useOpenModal(ModalType.Unspecified, "", filePreviewModalData);

    onMounted(async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const participantsResponse = await api.course.getCourseParticipantsByCourseIdAsync(courseId, {
          IsActive: true,
        });
        participants.value = participantsResponse.data;
        await Promise.allSettled([loadMessage(messageId, message), loadCourse(course, courseId)]);
      });
    });

    return {
      markRead,
      markUnread,
      answerEmail,
      showReplyModal,
      ModalType,
      messageId,
      message,
      participants,
      course: computed(() => store.state.courses.course),
      formattedDate: computed(() => (message.value ? formatDateTime(message.value.sent) : null)),
      hasAttatchment: computed(() => message.value?.attachments?.length),
      goBackToInbox: () => useNavigateBack(SingleCourseRouteNames.CourseMessages),
      filePreviewModalData,
      showFilePreviewModal,
      getFilePreviewData,
    };
  },
});

const getFilePreviewData = (file: ApiAttachmentDto): FilePreviewData => ({
  id: file.fileId,
  name: file.fileName,
  mimeType: "application/pdf",
});

const loadCourse = async (course: Ref<ApiGetCourseDto | null>, courseId: number) =>
  api.course.getCourseByIdFilteredByDepartmentAsync(courseId).then((response) => {
    course.value = response.data;
  });

const setMessageReadStatus = async (messageId: number, isRead: boolean) => {
  globalLoadingWrapper({ blocking: true }, async () => {
    await api.messaging.setReadKursadmin(messageId, {
      isRead,
    });
  });
};

const loadMessage = (messageId: number, message: Ref<ApiGetMessageResultDto | null>) =>
  api.messaging.getMessageByIdKursAdmin(messageId).then((response) => {
    message.value = mapMessageResponse(response.data);
  });
