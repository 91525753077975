import { ApiGetMessageResultDto } from "@/api/generated/Api";
import { ApiGetMessageResult } from "@/shared/interfaces/ApiGetMessageResult.interface";
import { formatDateTime } from "./dateHelpers";

export const mapMessageResponse = (message: ApiGetMessageResultDto): ApiGetMessageResult => {
  const getName = (recipients: string[]) => {
    if (recipients.length > 0) {
      return recipients.join(", ");
    }
    return "Navn Mangler";
  };
  const messageDate = new Date(message.sent);
  const displayDate = formatDateTime(messageDate);
  return {
    ...message,
    senderName: message.sender ?? "Ukjent",
    recipientName: getName(message.recipients ?? []),
    displayDate,
  };
};
