var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.smsList.length > 0 ? _c('div', [_c('v-list', {
    staticClass: "pa-0",
    attrs: {
      "two-line": ""
    }
  }, [_vm._l(_vm.visibleMessages, function (sms, index) {
    return [_c('v-list-item', {
      key: sms.title,
      attrs: {
        "color": "primary",
        "data-cy": 'message-' + sms.id
      }
    }, [_c('v-icon', {
      staticClass: "mr-2"
    }, [_vm._v(_vm._s(sms.isRead ? "mdi-email-open" : "mdi-email"))]), _c('v-list-item-content', [_c('v-list-item-subtitle', {
      staticClass: "d-flex flex-row"
    }, [_c('h5', {
      staticClass: "pt-1"
    }, [_vm._v("Til:")]), _vm._l(sms.recipients, function (recipient, index) {
      return _c('div', {
        key: index
      }, [_c('v-chip', {
        staticClass: "mx-1",
        attrs: {
          "small": "",
          "label": ""
        }
      }, [_c('v-icon', {
        attrs: {
          "small": "",
          "left": ""
        }
      }, [_vm._v(" mdi-account-circle-outline ")]), _vm._v(" " + _vm._s(recipient.name) + " ")], 1), _c('v-chip', {
        staticClass: "mx-1",
        attrs: {
          "small": "",
          "color": "primary",
          "label": ""
        }
      }, [_c('v-icon', {
        attrs: {
          "small": "",
          "left": ""
        }
      }, [_vm._v(" mdi-phone ")]), _vm._v(" " + _vm._s(recipient.mobileNumber) + " ")], 1)], 1);
    })], 2), _c('v-list-item-subtitle', {
      staticClass: "text-wrap",
      attrs: {
        "data-cy": "message-text",
        "data-private": ""
      },
      domProps: {
        "textContent": _vm._s(sms.messageText)
      }
    })], 1), _c('v-list-item-action', [_c('v-list-item-action-text', {
      domProps: {
        "textContent": _vm._s(_vm.formatDateTime(sms.inserted))
      }
    })], 1)], 1), _c('v-divider', {
      key: index
    })];
  })], 2), _c('v-pagination', {
    staticClass: "pa-3",
    attrs: {
      "length": Math.ceil(_vm.totalMessages / _vm.pageSize)
    },
    model: {
      value: _vm.page,
      callback: function callback($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1) : _c('v-alert', {
    staticClass: "ma-4 pb-4",
    attrs: {
      "text": "",
      "type": "info",
      "icon": "mdi-email-open-outline"
    }
  }, [_vm._v("Ingen meldinger")]);
}
var staticRenderFns = []

export { render, staticRenderFns }