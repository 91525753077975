var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.showNewEmailModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 1200
    },
    on: {
      "close": _vm.closeNewEmailModal
    }
  }, [_c('CourseSendMessageModal', {
    attrs: {
      "headline": "Send melding",
      "modalType": _vm.ModalType.Add,
      "possibleRecipients": _vm.participants
    },
    on: {
      "close": _vm.closeNewEmailModal
    }
  })], 1) : _vm._e(), _vm.course ? _c('BaseLayout', {
    staticClass: "px-0",
    attrs: {
      "display-tabs": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "left": ""
          },
          on: {
            "click": _vm.navigateToCourseDashboard
          }
        }, [_vm._v("mdi-arrow-left")]), _vm._v(" " + _vm._s(_vm.$route.params.id) + " - " + _vm._s(_vm.course.courseName) + " ")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_vm.participants ? _c('v-btn', {
          attrs: {
            "color": "white",
            "outlined": "",
            "data-cy": "open-new-message-popup"
          },
          on: {
            "click": _vm.openNewEmailModal
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-email-edit")]), _vm._v(" Send ny melding ")], 1) : _vm._e(), _c('v-btn', {
          staticClass: "ml-2",
          attrs: {
            "color": "white",
            "outlined": ""
          },
          on: {
            "click": _vm.updateTabView
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-filter-variant")]), _vm._v(" " + _vm._s(_vm.isFilterOpen ? "Skjul filter" : "Vis filter") + " ")], 1)];
      },
      proxy: true
    }, {
      key: "tabs",
      fn: function fn() {
        return [_c('v-tabs', {
          model: {
            value: _vm.activeTab,
            callback: function callback($$v) {
              _vm.activeTab = $$v;
            },
            expression: "activeTab"
          }
        }, [_c('v-tab', {
          attrs: {
            "href": "#inbox",
            "data-cy": "inbox-tab"
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-inbox-full")]), _c('v-badge', {
          attrs: {
            "content": _vm.unreadCount,
            "value": _vm.unreadCount,
            "color": "error"
          }
        }, [_vm._v("Innboks")])], 1), _c('v-tab', {
          attrs: {
            "href": "#sendt",
            "data-cy": "sendt-tab"
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-send")]), _vm._v("Utboks")], 1), _c('v-tab', {
          attrs: {
            "href": "#sms",
            "data-cy": "sms-tab"
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-cellphone-text")]), _vm._v("Sendte sms")], 1)], 1)];
      },
      proxy: true
    }], null, false, 2517749577)
  }, [[_vm.isFilterOpen ? _c('v-row', {
    staticClass: "px-4"
  }, [_c('v-col', [_c('v-autocomplete', {
    attrs: {
      "items": _vm.avaliableSubjects,
      "label": "Emne",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.subject,
      callback: function callback($$v) {
        _vm.subject = $$v;
      },
      expression: "subject"
    }
  })], 1), _vm.activeTab === _vm.MessageTabNames.Sendt ? _c('v-col', [_c('v-autocomplete', {
    attrs: {
      "items": _vm.avaliableRecipients,
      "label": "Til",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.to,
      callback: function callback($$v) {
        _vm.to = $$v;
      },
      expression: "to"
    }
  })], 1) : _vm._e(), _vm.activeTab === _vm.MessageTabNames.Inbox ? _c('v-col', [_c('v-autocomplete', {
    attrs: {
      "items": _vm.avaliableSenders,
      "label": "Fra",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.from,
      callback: function callback($$v) {
        _vm.from = $$v;
      },
      expression: "from"
    }
  })], 1) : _vm._e(), _c('v-col', [_c('v-text-field', {
    attrs: {
      "clearable": "",
      "hide-details": "",
      "label": "Innhold"
    },
    model: {
      value: _vm.content,
      callback: function callback($$v) {
        _vm.content = $$v;
      },
      expression: "content"
    }
  })], 1), _c('v-col', [_vm.activeTab === _vm.MessageTabNames.Inbox ? _c('v-btn', {
    staticClass: "mt-2",
    attrs: {
      "block": "",
      "color": "primary",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        _vm.displayUnread = !_vm.displayUnread;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-filter-variant")]), _vm._v(" " + _vm._s(_vm.displayUnread ? "Vis alle" : "Vis uleste") + " ")], 1) : _vm._e()], 1)], 1) : _vm._e(), _c('v-divider'), _vm.isFilterOpen ? _c('CourseMessageList', {
    attrs: {
      "messages": _vm.searchInMessages
    }
  }) : _c('v-tabs-items', {
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": _vm.MessageTabNames.Inbox
    }
  }, [_c('CourseMessageList', {
    attrs: {
      "messages": _vm.messagesInbox
    }
  })], 1), _c('v-tab-item', {
    attrs: {
      "value": _vm.MessageTabNames.Sendt
    }
  }, [_c('CourseMessageList', {
    attrs: {
      "messages": _vm.messagesOutbox
    }
  })], 1), _c('v-tab-item', {
    attrs: {
      "value": _vm.MessageTabNames.SMS
    }
  }, [_c('CourseSmsList', {
    attrs: {
      "smsList": _vm.smsList
    }
  })], 1)], 1)]], 2) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }