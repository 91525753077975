var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('base-layout', {
    attrs: {
      "no-container": "",
      "displayTabs": _vm.replyMessageRecipient ? false : true
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v(_vm._s(_vm.headline) + " ")];
      },
      proxy: true
    }, {
      key: "tabs",
      fn: function fn() {
        return [_c('v-tabs', {
          model: {
            value: _vm.activeTab,
            callback: function callback($$v) {
              _vm.activeTab = $$v;
            },
            expression: "activeTab"
          }
        }, [_c('v-tab', {
          attrs: {
            "href": "#internal"
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-inbox-full")]), _vm._v(" Melding til minsidebruker ")], 1), _c('v-tab', {
          attrs: {
            "href": "#sms"
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-cellphone-text")]), _vm._v("SMS")], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('BaseModalForm', {
    attrs: {
      "modalType": _vm.modalType,
      "submitButtonText": "Send"
    },
    on: {
      "submit": _vm.handleSubmit,
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-form', {
    ref: "connectForm",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.validForm,
      callback: function callback($$v) {
        _vm.validForm = $$v;
      },
      expression: "validForm"
    }
  }, [_vm.courseContainsRestrictedUser && !_vm.isSms ? _c('v-row', [_c('v-col', {
    staticClass: "py-0 font-weight-black"
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "color": "red"
    }
  }, [_vm._v("mdi-flag")]), _vm._v(" NB! Dette kurset inneholder deltakere med begrenset tilgang. Bruk SMS for å kontakte dem! ")], 1)], 1) : _vm._e(), !_vm.replyMessageRecipient ? _c('v-row', [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-radio-group', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', [_vm._v("Send til gruppe eller en deltager")])];
      },
      proxy: true
    }], null, false, 3017468782),
    model: {
      value: _vm.sendToChoice,
      callback: function callback($$v) {
        _vm.sendToChoice = $$v;
      },
      expression: "sendToChoice"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "Alle (b\xE5de ressurser og personer med alle statuser)",
      "value": _vm.SendToChoice.Everyone
    }
  }), _c('v-radio', {
    attrs: {
      "label": "Subgruppe",
      "value": _vm.SendToChoice.Group
    }
  }), _c('v-radio', {
    attrs: {
      "label": "Person(er)",
      "value": _vm.SendToChoice.Person
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.replyMessageRecipient ? _c('v-row', [_c('v-col', {
    staticClass: "py-0"
  }, [_vm.replyMessageRecipient ? _c('v-text-field', {
    attrs: {
      "label": "Til",
      "value": _vm.replyMessageRecipient,
      "readonly": ""
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), !_vm.replyMessageRecipient && _vm.sendToChoice !== _vm.SendToChoice.Everyone ? _c('v-row', [_c('v-col', {
    staticClass: "py-0"
  }, [_vm.sendToChoice === _vm.SendToChoice.Person ? _c('v-select', {
    attrs: {
      "label": "Til",
      "items": !_vm.isSms ? _vm.filteredRecipientPeopleList : _vm.recipientPeopleList,
      "rules": _vm.validateNotEmpty,
      "item-text": "label",
      "item-value": "value",
      "multiple": "",
      "dense": ""
    },
    model: {
      value: _vm.recipientPeople,
      callback: function callback($$v) {
        _vm.recipientPeople = $$v;
      },
      expression: "recipientPeople"
    }
  }) : _vm._e(), _vm.sendToChoice === _vm.SendToChoice.Group ? _c('v-select', {
    attrs: {
      "label": "Til gruppe",
      "items": _vm.recipientGroupList,
      "item-text": "label",
      "item-value": "value",
      "dense": ""
    },
    model: {
      value: _vm.recipientGroups,
      callback: function callback($$v) {
        _vm.recipientGroups = $$v;
      },
      expression: "recipientGroups"
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), !_vm.replyMessageRecipient && !_vm.isSms ? _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Malgruppe",
      "items": _vm.templateGroups,
      "item-text": "name",
      "item-value": "id",
      "dense": "",
      "clearable": "",
      "data-cy": "new-message-template-group"
    },
    on: {
      "change": _vm.onChangeSelectedTemplateGroup
    },
    model: {
      value: _vm.selectedTemplateGroupId,
      callback: function callback($$v) {
        _vm.selectedTemplateGroupId = $$v;
      },
      expression: "selectedTemplateGroupId"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Kommunikasjonsmal",
      "items": _vm.filteredActiveTemplates,
      "item-text": "name",
      "item-value": "id",
      "dense": "",
      "clearable": "",
      "data-cy": "new-message-template"
    },
    on: {
      "change": _vm.onChangeSelectedTemplate
    },
    model: {
      value: _vm.selectedTemplateId,
      callback: function callback($$v) {
        _vm.selectedTemplateId = $$v;
      },
      expression: "selectedTemplateId"
    }
  })], 1)], 1) : _vm._e(), !_vm.isSms ? _c('v-row', [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    staticClass: "pt-0",
    attrs: {
      "label": "Tittel",
      "dense": "",
      "rules": _vm.validateNotEmpty,
      "data-cy": "new-message-title",
      "data-private": ""
    },
    model: {
      value: _vm.title,
      callback: function callback($$v) {
        _vm.title = $$v;
      },
      expression: "title"
    }
  })], 1)], 1) : _vm._e(), !_vm.isSms ? _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-file-input', {
    staticClass: "pt-0",
    attrs: {
      "chips": "",
      "dense": "",
      "multiple": "",
      "show-size": "",
      "counter": "",
      "accept": ".pdf",
      "label": "Vedlegg"
    },
    model: {
      value: _vm.attatchments,
      callback: function callback($$v) {
        _vm.attatchments = $$v;
      },
      expression: "attatchments"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('Editor', {
    ref: "tuiEditor",
    attrs: {
      "data-cy": "new-message-text",
      "initialValue": _vm.message,
      "options": _vm.editorOptions
    },
    on: {
      "change": _vm.onChangeEditor
    }
  })], 1)], 1) : _vm._e(), _vm.isSms ? _c('v-row', [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-textarea', {
    staticClass: "pt-0",
    attrs: {
      "label": "Innhold",
      "counter": "500",
      "rules": [_vm.validateMaxLength()].concat(_vm.validateNotEmpty),
      "filled": "",
      "data-private": ""
    },
    model: {
      value: _vm.smsContent,
      callback: function callback($$v) {
        _vm.smsContent = $$v;
      },
      expression: "smsContent"
    }
  })], 1)], 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }