
import { api } from "@/api/api";
import { ApiGetCourseParticipantDto, ApiGetMessageResultDto, ApiGetSmsResultDto } from "@/api/generated/Api";
import CourseMessageList from "@/components/course/details/messages/CourseMessageList.vue";
import CourseSendMessageModal from "@/components/course/details/messages/CourseSendMessageModal.vue";
import CourseSmsList from "@/components/course/details/messages/CourseSmsList.vue";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import { CourseMessageBoxType } from "@/shared/enums/CourseMessageBoxType.enum";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { hasMemberOrgAccess } from "@/shared/helpers/accessLevelApiAdapter";
import { uniqueArray } from "@/shared/helpers/arrayHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { mapMessageResponse } from "@/shared/helpers/messageHelpers";
import { useRoute, useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, Ref, ref, watch } from "@vue/composition-api";

enum MessageTabNames {
  Inbox = "inbox",
  Sendt = "sendt",
  SMS = "sms",
}

export default defineComponent({
  name: "CourseMessagesPage",

  components: { CourseMessageList, CourseSendMessageModal, BaseModal, BaseLayout, CourseSmsList },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore<StoreState>();
    const courseId = +route.params.id;
    const isFilterOpen = ref(route.query.isFilterOpen === "true" || false);
    const displayUnread = ref(route.query.undread === "true" || false);
    const smsList = ref<ApiGetSmsResultDto[]>([]);
    const validateQueryString = (value: string | (string | null)[]) => (typeof value === "string" ? value : undefined);
    const subject = ref(validateQueryString(route.query.subject));
    const from = ref(validateQueryString(route.query.sender));
    const to = ref(validateQueryString(route.query.to));
    const content = ref(validateQueryString(route.query.from));
    const messagesInbox = ref<ApiGetMessageResultDto[]>([]);
    const messagesOutbox = ref<ApiGetMessageResultDto[]>([]);
    const allMessages = ref<ApiGetMessageResultDto[]>([]);
    const showNewEmailModal = ref(false);
    const participants = ref<ApiGetCourseParticipantDto[]>([]);
    const activeTab = ref(route.query.tab ?? MessageTabNames.Inbox);

    const unreadCount = computed(
      () => allMessages.value.filter((message) => !message.isRead && !message.sentFromKursAdmin).length
    );

    const searchInMessages = computed(() => {
      router.replace({
        query: {
          tab: activeTab.value,
          isFilterOpen: isFilterOpen.value.toString(),
          displayUnread: displayUnread.value.toString(),
          subject: subject.value,
          to: to.value,
          from: from.value,
          content: content.value,
        },
      });
      return allMessages.value
        .filter((currentMessage) =>
          activeTab.value === MessageTabNames.Inbox ? !currentMessage.sentFromKursAdmin : true
        )
        .filter((currentMessage) =>
          activeTab.value === MessageTabNames.Sendt ? currentMessage.sentFromKursAdmin : true
        )
        .filter((currentMessage) => (displayUnread.value ? !currentMessage.isRead : true))
        .filter((currentMessage) => (subject.value ? currentMessage.subject?.includes(subject.value) : true))
        .filter((currentMessage) => (from.value ? currentMessage.sender?.includes(from.value) : true))
        .filter((currentMessage) => (to.value ? currentMessage.recipients?.join(", ")?.includes(to.value) : true))
        .filter((currentMessage) =>
          content.value ? currentMessage.text?.toLowerCase()?.includes(content.value.toLowerCase()) : true
        );
    });

    watch(activeTab, () => {
      router.replace({ query: { tab: activeTab.value } });
    });

    onMounted(async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const participantsResponse = await api.course.getCourseParticipantsByCourseIdAsync(courseId, {
          IsActive: true,
        });
        participants.value = participantsResponse.data;
      });
      reloadMessages();
    });

    const reloadMessages = async () => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        await Promise.allSettled([
          loadSendtSms(),
          loadMessages(courseId, messagesInbox, undefined, false),
          loadMessages(courseId, messagesOutbox, true, true),
          loadMessages(courseId, allMessages),
        ]);
      });
    };

    const loadSendtSms = async () => {
      smsList.value = (await api.messaging.getSmsMessagesKursadmin({ CourseId: courseId })).data;
    };

    const updateTabView = () => {
      isFilterOpen.value = !isFilterOpen.value;
      if (!isFilterOpen.value) {
        router.replace({ query: { tab: activeTab.value } });
        return;
      }
      router.replace({ query: { isFilterOpen: "true" } });
    };

    const openNewEmailModal = () => {
      showNewEmailModal.value = true;
    };

    const closeNewEmailModal = async () => {
      showNewEmailModal.value = false;
      await reloadMessages();
    };

    const navigateToCourseDashboard = () =>
      router.push({
        name: SingleCourseRouteNames.CourseDashboard,
      });

    return {
      smsList,
      subject,
      from,
      to,
      content,
      isFilterOpen,
      unreadCount,
      displayUnread,
      openNewEmailModal,
      updateTabView,
      CourseMessageBoxType,
      messagesInbox,
      messagesOutbox,
      searchInMessages,
      activeTab,
      showNewEmailModal,
      ModalType,
      closeNewEmailModal,
      participants,
      MessageTabNames,
      navigateToCourseDashboard,
      course: computed(() => store.state.courses.course),
      avaliableSubjects: computed(() =>
        uniqueArray(searchInMessages.value.map((currentMessage) => currentMessage.subject))
      ),
      avaliableSenders: computed(() =>
        uniqueArray(searchInMessages.value.map((currentMessage) => currentMessage.sender))
      ),
      avaliableRecipients: computed(() =>
        uniqueArray(searchInMessages.value.map((currentMessage) => currentMessage.recipients?.join(", ")))
      ),
    };
  },
});

export const loadMessages = (
  courseId: number,
  messages: Ref<ApiGetMessageResultDto[]>,
  isRead?: boolean,
  sendt?: boolean
) => {
  if (hasMemberOrgAccess) {
    return;
  }
  api.messaging
    .searchMessagesKursadmin({
      CourseId: courseId,
      IsRead: isRead,
      Sender: sendt,
    })
    .then((response) => {
      if (!response.data.items) {
        messages.value = [];
        return;
      }
      messages.value = response.data.items.map((current) => mapMessageResponse(current));
    });
};
