
import { ApiGetMessageResultDto } from "@/api/generated/Api";
import { CourseMessageBoxType } from "@/shared/enums/CourseMessageBoxType.enum";
import { computed, defineComponent, PropType, ref } from "@vue/composition-api";

export default defineComponent({
  name: "CourseMessageList",
  components: {},
  props: {
    messages: {
      type: Array as PropType<ApiGetMessageResultDto[]>,
      required: true,
    },
    onDashboard: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const pageSize = 7;
    const page = ref(1);

    const getMessageNavTarget = (element: ApiGetMessageResultDto) => {
      if (props.onDashboard) {
        return `/kurset/${element.courseId}/messages/${element.id}`;
      }
      return `messages/${element.id}`;
    };

    const hasAttatchment = (message: ApiGetMessageResultDto) => {
      if (!message.attachments) {
        return false;
      }
      return message.attachments.length > 0;
    };

    return {
      page,
      pageSize,
      getMessageNavTarget,
      CourseMessageBoxType,
      hasAttatchment,
      totalMessages: computed(() => props.messages.length),
      visibleMessages: computed(() => props.messages.slice((page.value - 1) * pageSize, page.value * pageSize)),
    };
  },
});
